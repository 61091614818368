import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tabs,
  notification,
  Popover
} from "antd";

import { useEffect, useState, useRef } from "react";
import {
  getProjects,
  changePasswordRequest,
  restoreProject,
} from "../../helpers/apiCall";
import { UndoOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

function User() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [oldPasswordModalVisible, setOldPasswordModalVisible] = useState(false);

  const passwordElement = useRef(null);
  const passwordConfirmElement = useRef(null);
  
  const [passwordRules, setPasswordRules] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const content = () => {
    return (
      <div>
        <div>
          {passwordRules.minLength ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          8 to 64 characters
        </div>
        <div>
          {passwordRules.lowerCase ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          One lowercase letter
        </div>
        <div>
          {passwordRules.upperCase ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          1 upper case
        </div>
        <div>
          {passwordRules.specialChar ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          {"One Symbol (!#$%&()*+,-./:;<=>?@[]^_`{|}~)"}
        </div>
        <div>
          {passwordRules.numerical ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          One digit (0-9)
        </div>
      </div>
    );
  };

  const refreshProjects = () => {
    return getProjects(false).then((res) => {
      setProjects(res?.data);
    }).catch(e => {
      api.error({
        message: e.message,
        description: e.response?.data?.detail,
      });
    });
  };

  useEffect(() => {
    refreshProjects();
  }, []);

  const restore_project = (id) => {
    setLoading(true);
    restoreProject(id).then((res) => {
      refreshProjects().then(() => {
        setLoading(false);
      });
    }).catch(e => {
      api.error({
        message: e.message,
        description: e.response?.data?.detail,
      });
    });
  };

  const cols = [
    { key: "name", dataIndex: "name", title: "Project name" },
    {
      key: "active",
      dataIndex: "active",
      title: "Status",
      render: (e, record) => {
        return (
          <span>
            {e ? "Active" : "Inactive"}
            {e ? null : (
              <Popconfirm
                title="Restore project"
                description="Are you sure to restore this project?"
                onConfirm={() => restore_project(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <UndoOutlined
                  title="Restore"
                  style={{ fontSize: 16, marginLeft: 10 }}
                />
              </Popconfirm>
            )}
          </span>
        );
      },
    },
    { key: "workStream", dataIndex: "project_type", title: "Work stream" },
  ];

  const validatePassword = (value) => {
    const errors = {
      minLength: true,
      upperCase: false,
      lowerCase: false,
      numerical: false,
      specialChar: false,
    };

    if (value?.length < 8) errors.minLength = false;
    if (/(?=.*?[A-Z])/.test(value)) errors.upperCase = true;
    if (/(?=.*?[a-z])/.test(value)) errors.lowerCase = true;
    if (/(?=.*?[0-9])/.test(value)) errors.numerical = true;
    if (/(?=.*?[#?!@$%^&*-])/.test(value)) errors.specialChar = true;

    const hasErrors = Object.values(errors).filter((e) => e === false);
    setHasErrors(hasErrors.length > 0);

    setPasswordRules(errors);
    setNewPassword(value);
  };

  const resetPassword = () => {
    if (hasErrors) {
      passwordElement.current.focus();
      return;
    }

    changePasswordRequest(newPassword, newPasswordConfirm, oldPassword)
      .then((res) => {
        api.success({
          message: "Success",
          description: "Password reset successfully.",
        });
      })
      .catch((e) => {
        api.error({
          message: e.message,
          description: e.response?.data?.detail,
        });
      });

      oldPasswordModalToggle()
  };

  const oldPasswordModalToggle = () => {
    setOldPasswordModalVisible(!oldPasswordModalVisible)
  }

  return (
    <div
      style={{
        padding: "150px 50px 0px",
        fontSize: 22,
        letterSpacing: 2,
      }}
    >
      {contextHolder}
      <Modal 
        title="Enter your current password" 
        open={oldPasswordModalVisible}
        onCancel={oldPasswordModalToggle}
        onOk={resetPassword}
        okText="Change password"
        styles={{
          body: {
            height: 100
          }
        }}
      >
        <Input
          type="password"
          data-testid="oldPassword"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          />
      </Modal>
      <Tabs
        items={[
          {
            key: 1,
            label: <b>Change password</b>,
            children: (
              <div style={{ width: 500 }}>
                <Form>
                  <div>
                    <label>New password:</label>
                    <Popover
                      content={content}
                      title="Your new password must contain each of the following"
                      placement="right"
                      trigger="focus"
                      >       
                      <Input
                        type="password"
                        data-testid="newPassword"
                        onChange={(e) => validatePassword(e.target.value)}
                        ref={passwordElement}
                      />
                    </Popover>
                  </div>
                  <br />
                  <div>
                    <label>Confirm new password:</label>
                    <Popover
                      content={<div>
                        <CloseCircleTwoTone twoToneColor="#ff0303" /> Passwords do not match
                      </div>}
                      title="Password rules"
                      placement="right"
                      open={passwordElement.current?.input !== document.activeElement && newPassword !== newPasswordConfirm}
                    >
                      <Input
                        type="password"
                        data-testid="newPasswordConfirm"
                        value={newPasswordConfirm}
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        ref={passwordConfirmElement}
                      />
                    </Popover>
                  </div>
                  <br />
                  <Button type="primary" onClick={oldPasswordModalToggle} disabled={newPassword !== newPasswordConfirm}>
                    Change
                  </Button>
                </Form>
              </div>
            ),
          },
          {
            key: 2,
            label: <b>Projects</b>,
            children: (
              <Table columns={cols} dataSource={projects} loading={loading} />
            ),
          },
        ]}
      />
    </div>
  );
}
export default User;
