import axios from "axios";
import baseUrl from "./baseURL";

const request = (method, url, data, headers = {}) => {
  const token = localStorage.getItem("token");

  return axios({
    method,
    url,
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).catch((e) => {
    console.log({ e });
    const status = e.response?.status;
    const refresh_token = localStorage.getItem("refresh_token");

    if (status === 401) {
      // try to refresh the token
      if(refresh_token){
        return axios({
          method: "get",
          url: baseUrl + "/auth/refresh_token",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            refresh_token: refresh_token,
          },
        })
        .then((res) => {
          localStorage.setItem("token", res?.data?.access_token);
        })
        .catch((ee) => {
          localStorage.clear();
          window.location = "/";
        });
      }
      else{
        localStorage.clear();
      }
    }
    else if (status === 429) {
      throw status;
    }
    
    throw e;
  });
};

export const loginAction = (username, password) => {
  const bodyFormData = new FormData();

  bodyFormData.append("username", username);
  bodyFormData.append("password", password);

  return request("post", baseUrl + "/auth/login", bodyFormData, {
    "Content-Type": "application/x-www-form-urlencoded",
  }).then((res) => {
    if (res.status === 200) {
      localStorage.setItem("userId", res?.data?.user_id);
      localStorage.setItem("token", res?.data?.access_token);
      localStorage.setItem("refresh_token", res?.data?.refresh_token);

      // get user data
      request("get", baseUrl + "/users/" + res?.data?.user_id).then((user) => {
        localStorage.setItem("email", user?.data?.email);
      });
    }
    return res;
  });
};

export const createProject = (fileName, projectName, workStream) => {
  return request("post", baseUrl + "/projects/", {
    name: projectName,
    project_type: workStream,
    file_name: fileName,
    input_data: {},
    tmp_filename: "",
  });
};

export const uploadProjectFile = (projectId, ovidfile) => {
  const token = localStorage.getItem("token");

  return request(
    "post",
    baseUrl + "/projects/" + projectId + "/data/ovid",
    {
      ovidfile,
    },
    {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    }
  );
};

export const getProjects = (activeOnly = true) => {
  return request("get", baseUrl + "/projects/list?active_only=" + activeOnly);
};

export const restoreProject = (projectId) => {
  return request("post", baseUrl + "/projects/" + projectId + "/reactivate");
};

export const getProjectDetails = (id) => {
  return request("get", baseUrl + "/projects/" + id);
};

export const changePasswordRequest = (password, confirm_password, current_password) => {
  const userId = localStorage.getItem("userId");

  return request("put", baseUrl + "/users/" + userId + '/password', {
    password,
    confirm_password,
    current_password
  }).then(res => {
    // update user tokens
    localStorage.setItem("token", res?.data?.access_token);
    localStorage.setItem("refresh_token", res?.data?.refresh_token);
  });
};

export const resetPasswordRequest = (email) => {
  return request("post", baseUrl + "/users/reset_password_email?email=" + email)
};

export const sessionCheck = () => {
  return request("get", baseUrl + "/session_check");
};

export const initScreening = (
  project_id,
  pop_inclusion,
  pop_exclusion,
  ai_concepts_choice,
  stdy_design,
  outc_meas
) => {
  return request("post", baseUrl + "/projects/" + project_id + "/screening", {
    pop_inclusion,
    pop_exclusion,
    ai_concepts_choice,
    stdy_design,
    outc_meas,
  });
};

export const getResults = (project_id) => {
  return request(
    "get",
    baseUrl + "/projects/" + project_id + "/screening/results"
  );
};

export const getSummary = (project_id) => {
  return request(
    "get",
    baseUrl + "/projects/" + project_id + "/screening/summary"
  );
};

export const deleteProject = (project_id) => {
  return request("post", 
    baseUrl + "/projects/" + project_id + '/softdelete'
  );
};

export const getFieldsValues = (project_id) => {
  return request(
    "get",
    baseUrl + "/projects/" + project_id + "/screening/screening_data"
  );
};

export const getConcepts = (project_id) => {
  return request(
    "get",
    baseUrl + "/projects/" + project_id + "/screening/mapped_concepts"
  ).then((res) => {
    const concepts = res?.data?.ai_concepts?.reduce((acc, curr, ndx) => {
      if (curr.parent_concept) {
        acc.push({
          ...curr,
          id: ndx,
          Concepts: curr.Concepts.trim(),
          children: [],
        });
      } else {
        acc[acc.length - 1].children.push({
          ...curr,
          id: ndx,
          Concepts: curr.Concepts.trim(),
        });
      }
      return acc;
    }, []);
    return concepts;
  }).catch(e => {
    return []
  });
};

export const getReport = (project_id) => {
  const token = localStorage.getItem("token");
  return request(
    "get",
    baseUrl + "/projects/" + project_id + "/screening/report",
    undefined,
    { accept: "*/*" }
  ).then((fileInfo) => {
    return fetch(baseUrl + "/projects/" + project_id + "/screening/report", {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        accept: "*/*",
      }),
    }).then((res) => {
      const data = res.blob();
      return { infos: fileInfo, data };
    });
  });
};

export const logoutAction = () => {
    const refresh_token = localStorage.getItem("refresh_token");
    const token = localStorage.getItem("token");

    return axios({
      method: "get",
      url: baseUrl + "/auth/logout",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        refresh_token: refresh_token,
      },
    })
    .then((res) => {
      localStorage.clear();
      window.location = "/";
      return res
    })
    .catch((ee) => {
      localStorage.clear();
      window.location = "/";
    });
};