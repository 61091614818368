import { Switch, Route, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";

// Styles
import "./App.css";
// import "antd/dist/antd.min.css";

// Components
import Drawer from "./components/SideBar";
import TopBar from "./components/TopBar";
// import Loader from "./components/Loader";

// Pages
// import HomePage from "./components/HomePage";
import Screening from "./pages/Screening";
import Upload from "./pages/Upload";
import Results from "./pages/Results";
import Projects from "./pages/Projects";
import About from "./pages/About";
import User from "./pages/User";
import SignUp from "./pages/SignUp";

// Libraries
import LoginPage from "./pages/LoginPage";

function App() {
  const [sideBarCollapsed, toggleSideBar] = useState(false);
  const [currentProject, setCurrentProject] = useState({});
  const [appKey, setAppKey] = useState("");

  const accessToken = localStorage.getItem("token");
  const refreshToken= localStorage.getItem("refresh_token");

  return (
    <div className="App" key={appKey}>
      <Switch>
        <Route 
          path="/" 
          exact 
          render={() => <LoginPage setAppKey={setAppKey} />}
        />
        <Route path="/signup" component={SignUp} />
        {accessToken || refreshToken ? 
          <div
            className="dashboard"
            style={{
              fontFamily: "NeuePlakExtRegular",
            }}
          >
            <Drawer
              toggleSideBar={toggleSideBar}
              sideBarCollapsed={sideBarCollapsed}
              setCurrentProject={setCurrentProject}
            />
            <TopBar
              sideBarCollapsed={sideBarCollapsed}
              currentProject={currentProject}
              setCurrentProject={setCurrentProject}
            />
            <div
              className="appHolder"
              style={{
                height: "100vh",
                width: `calc(100vw - ${sideBarCollapsed ? 100 : 260}px)`,
                float: "right",
                overflow: "auto",
              }}
            >
              <Route
                path="/about"
                exact
                render={() => <About setCurrentProject={setCurrentProject} />}
              />
              <Route
                path="/dashboard"
                render={() => (
                  <Upload
                    setAppKey={setAppKey}
                    setCurrentProject={setCurrentProject}
                  />
                )}
              />
              <Route
                path="/screening"
                render={() => (
                  <Screening
                    currentProject={currentProject}
                    setCurrentProject={setCurrentProject}
                    setAppKey={setAppKey}
                  />
                )}
              />
              <Route path="/results" component={Results} />
              <Route path="/user" component={User} />
              <Route path="/projects" component={Projects} />
              {/* <Route path="/signup" component={SignUp} /> */}
            </div>
          </div>  
          : <Redirect to="/" />
        }
      </Switch>
    </div>
  );
}

export default App;
