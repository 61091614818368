import { Button, Collapse, Table, notification } from "antd";
import ReactFlow from "reactflow";
import { nodes as initialNodes, edges as initialEdges } from "./elements";

import "reactflow/dist/style.css";
import { useEffect } from "react";
import { getConcepts, getReport, getSummary } from "../../helpers/apiCall";

import { useState } from "react";

const { Panel } = Collapse;

const colsA = [
  { title: "", dataIndex: "", width: 50 },
  { title: "Concepts", dataIndex: "Concepts" },
  { title: "Citations", dataIndex: "Citations" },
  { title: "References", dataIndex: "References" },
];
const colsB = [
  {
    title: "COA Measurement",
    dataIndex: "Measurement",
  },
  {
    title: "Number of Patients",
    dataIndex: "properties",
  },
  {
    title: "Publications",
    dataIndex: "publications",
  },
  {
    title: "CT.gov",
    dataIndex: "trials",
  },
];
const dataA = [
  {
    Measurement: "Pain",
    properties: "",
    publications: "12",
    trials: "",
  },
  {
    Measurement: "Fatigue",
    properties: "",
    publications: "9",
    trials: "",
  },
  {
    Measurement: "Work Productivity",
    properties: "",
    publications: "8",
    trials: "",
  },
  {
    Measurement: "...",
    properties: "",
    publications: "...",
    trials: "",
  },
  {
    Measurement: "...",
    properties: "",
    publications: "...",
    trials: "",
  },
];

const dataB = [
  {
    Measurement: "NCCN_FACT",
    properties: "",
    publications: "12",
    trials: "",
  },
  {
    Measurement: "FACT_LYM",
    properties: "",
    publications: "9",
    trials: "",
  },
  {
    Measurement: "EORTC_QLQ20",
    properties: "",
    publications: "8",
    trials: "",
  },
  {
    Measurement: "NCCN_FACT",
    properties: "",
    publications: "12",
    trials: "",
  },
];

function Results() {
  // const workStream = localStorage.getItem("workStream") || "A";
  const projectId = window.location.pathname.split("/").reverse()[0];
  const [flowChartData, setFlowChartData] = useState({});
  const [concepts, setConcepts] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const exportFile = () => {
    getReport(projectId).then(async (res) => {
      const fileName =
        res?.infos?.headers?.["content-disposition"]?.split("=")[1];
      const blob = await res.data;
      const href = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = href;

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(href);
    })
    .catch((e) => {
      api.error({
        message: e.message,
        description: e.response?.data?.detail,
      });
    });;
  };

  useEffect(() => {
    getSummary(projectId).then((res) => {
      setFlowChartData(res.data);
    })
    .catch(e => {
      api.error({
        message: e.message,
        description: e.response?.data?.detail,
      });
      setConcepts([]);
    });
    
    getConcepts(projectId).then((res) => {
      if(Array.isArray(res))
        setConcepts(res);
    })
    .catch(e => {
      api.error({
        message: e.message,
        description: e.response?.data?.detail,
      });
      setConcepts([]);
    });
  }, [projectId]);

  return (
    <div
      style={{
        padding: "150px 50px 0px",
      }}
    >
      {contextHolder}
      <Button type="primary" style={{ float: "right" }} onClick={exportFile}>
        Export
      </Button>
      <Collapse defaultActiveKey={["1"]} ghost>
        <Panel header={<h4>Flow Diagram</h4>} key="1">
          <div style={{ height: 400, margin: "auto", paddingTop: 30 }}>
            <ReactFlow
              nodes={initialNodes(flowChartData)}
              edges={initialEdges}
              zoomOnScroll={false}
              zoomOnPinch={false}
            />
          </div>
        </Panel>
        <div style={{ textAlign: "center" }}>
          <i>
            The hierarchy of exclusions follows the following order: duplicate,
            population, study design, outcome.
          </i>
        </div>
        {concepts?.length > 0 ? 
          (<Panel
            header={
              <h4>Concepts tabulated</h4>
            }
            key="2"
          >
            <Table
              rowKey={(r) => r.id}
              dataSource={concepts}
              columns={colsA}
              bordered
              />
          </Panel>) : null
        }
      </Collapse>
    </div>
  );
}

export default Results;
