import { Alert, Button, Collapse, Modal, Spin, Switch, notification, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  getFieldsValues,
  getProjectDetails,
  initScreening,
  uploadProjectFile
} from "../../helpers/apiCall";
import { TagsInput } from "react-tag-input-component";
import { useLocation } from "react-router-dom";
import readXlsxFile from "read-excel-file";

const { Panel } = Collapse;
const { Dragger } = Upload;

const initConcepts = [
  "Studies focusing on how the patient feels and functions",
  "Health-related quality of life",
  "Quality of life",
  "Burden",
  "Impact",
  "Sign",
  "Symptom",
  "Function",
];

const studyDesignsPatient = [
  "Qualitative research",
  "Interview",
  "Focus group",
  "Patient input",
  "Patient opinion",
  "Patient perspective",
  "Patient experience",
  "Subjective experience",
  "Lived experience",
  "Narrative",
  "Observational study",
];

const studyDesignsCaregiver = [
  "Qualitative research",
  "Interview",
  "Patient input",
  "Patient opinion",
  "Patient perspective",
  "Patient experience",
  "Focus group",
  "Subjective experience",
  "Narrative",
  "Lived experience",
  "Observational study",
  "Key informant",
  "Caregiver perspective",
  "Caretaker perspective",
  "Family",
  "Parent",
  "Sibling",
  "Sister",
  "Brother",
  "Spouse",
  "Wife",
  "Husband",
];

function Screening(props) {
  const [pageLoading, setPageLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [popInclusionSelected, setPopInclusionSelected] = useState([]);
  const [popExclusionSelected, setPopExclusionSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [caregiverSelected, setCaregiverSelected] = useState(false);
  const [dataSetModalVisible, setDataSetModalVisible ] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [concepts, setConcepts] = useState(initConcepts);
  const [patients, setPatients] = useState(studyDesignsPatient);
  const [caregivers, setCareGivers] = useState(studyDesignsCaregiver);

  const [aiConceptsChoice, setAiConceptsChoice] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const location = useLocation();


  const screening = () => {
    setLoading(true);
    const popInclusion = popInclusionSelected.join(";");
    const popExclusion = popExclusionSelected.join(";");
    const projectId = window?.location.pathname.split("/").reverse()[0];
    const studyDesign = caregiverSelected
      ? caregivers.join(";")
      : patients.join(";");
    const outcomeMeasurement = concepts.join(";");

    initScreening(
      projectId,
      popInclusion,
      popExclusion,
      aiConceptsChoice,
      studyDesign,
      outcomeMeasurement
    )
      .then((res) => {
        setLoading(false);
        api.success({
          message: "Success",
          description: "Screening in progress ..",
        });
      })
      .catch((e) => {
        console.log({ e });
        setLoading(false);
        api.error({
          message: e.message,
          description: e.response?.data?.detail,
        });
      });
  };

  const updateDataSetModal = () => {
    setDataSetModalVisible(!dataSetModalVisible)
  }

  useEffect(() => {
    if (!props.currentProject.id) {
      const id = location?.pathname.split("/")[2];

      getProjectDetails(id).then((res) => {
        props.setCurrentProject(res.data);
      }).catch(e => {
        api.error({
          message: e.message,
          description: e.response?.data?.detail,
        });
      });
    } else {
      getFieldsValues(props.currentProject.id)
        .then((res) => {
          const exc = res?.data?.screening_data?.pop_exclusion?.split(";") || [];
          setPopExclusionSelected(exc);

          const inc = res?.data?.screening_data?.pop_inclusion?.split(";") || [];
          setPopInclusionSelected(inc);
        })
        .catch((e) => {
          setPopExclusionSelected([]);
          setPopInclusionSelected([]);
          
          api.error({
            message: e.message,
            description: e.response?.data?.detail,
          });

          console.log({ e });
        });
    }
    setPageLoading(false);
  }, [props.currentProject.id]);

  const workStream = props.currentProject?.project_type;
  
  
  const draggerProps = {
    name: "file",
    beforeUpload(file) {
      readXlsxFile(file)
        .then(([cols, ...rows]) => {
          const mandatoryColumns = [
            "ORN",
            "DB",
            "PT",
            "AU",
            "JN",
            "VO",
            "IP",
            "PG",
            "TI",
            "AB",
          ];

          const valid = mandatoryColumns.every((mc) =>
            cols.some((c) => c === mc)
          );
          if (valid) {
            setUploading(true);
            uploadProjectFile(props.currentProject.id, file)
            .then(() => {
              api.success({
                message: "Success",
                description: "Dataset updated successfully.",
              });
              setDataSetModalVisible(false);
              setUploading(false);
              getProjectDetails(props.currentProject.id).then((res) => {
                props.setCurrentProject(res.data);
              });
            })
            .catch(e => {
              api.error({
                message: "Failed to update dataset.",
                description: e.response.data.detail,
              });
              setDataSetModalVisible(false);
              setUploading(false);
            })
          } else {
            api.error({
              message: "Error",
              description: "Failed to validate columns.",
            });
          }
          return valid;
        });
    },
    itemRender: () => <span />,
    customRequest(info) {
      return true;
    },
  };

  return (
    <div
      style={{
        padding: "120px 50px 0px",
      }}
    >
      {contextHolder}
      <Spin spinning={pageLoading} />
      <Alert 
        type="info" 
        message={
          <>
            <div style={{ paddingTop: 5, float: "left"}}>
              <b>Current dataset:</b> {props.currentProject.file_name} 
            </div> 
            <Button 
              type="primary" 
              style={{ float: "right"}} 
              onClick={updateDataSetModal}
            >
              Update dataset
            </Button> 
          </>
        } 
      />
      <br />
      <Collapse ghost activeKey={activeTab} onChange={(e) => setActiveTab(e)}>
        <Panel header={<h4>Population</h4>} key="1">
          <div
            style={{
              position: "relative",
            }}
            data-testid="populationInc"
          >
            <span
              style={{
                position: "absolute",
                top: -16,
                left: 7,
                padding: "0 15px 0 5px",
                background: "#fff",
                fontSize: 16,
              }}
            >
              Inclusion
            </span>
            <TagsInput
              value={popInclusionSelected}
              onChange={setPopInclusionSelected}
              separators={["Enter"]}
              name="popInclusion"
              placeHolder="Describe the included population followed by relevant keywords (e.g. 'adults with advanced mantle cell lymphoma; centrocytic lymphoma; lymphocytic lymphoma of intermediate differentiation')"
            />
          </div>
          <div
            style={{
              position: "relative",
              marginTop: 25,
              marginBBottom: 10,
            }}
            data-testid="populationExc"
          >
            <span
              style={{
                position: "absolute",
                top: -16,
                left: 7,
                padding: "0 15px 0 5px",
                background: "#fff",
                fontSize: 16,
              }}
            >
              Exclusion
            </span>
            <TagsInput
              style={{
                padding: 10,
              }}
              value={popExclusionSelected}
              onChange={setPopExclusionSelected}
              name="popExclusion"
              separators={["Enter"]}
              placeHolder="Describe the population exclusion criteria (e.g. 'Adults older than 65 years. Keywords: geriatric; myeloma; leukemia')"
            />
          </div>
          <br />
          <Button type="primary" onClick={() => setActiveTab(2)}>
            Next
          </Button>
        </Panel>
        <Panel header={<h4>Study Design</h4>} key="2">
          {workStream === "Conceptual Development" ? (
            <div style={{ width: "50%", margin: "auto" }}>
              <div style={{ padding: 10 }}>
                <span>Patient participants</span>
                <Switch
                  style={{ float: "right" }}
                  checked={!caregiverSelected}
                />
              </div>
              <div style={{ padding: 10 }}>
                <span>Caregiver participants</span>
                <Switch
                  value={caregiverSelected}
                  onChange={(v) => setCaregiverSelected(v)}
                  style={{ float: "right" }}
                />
              </div>
              <div style={{ padding: 10 }}>
                <span>HCP participants</span>
                <Switch style={{ float: "right" }} />
              </div>
              {/* <div style={{ padding: 10 }}>
                <span>Lit. review of concepts</span>
                <Switch style={{ float: "right" }} />
              </div> */}
            </div>
          ) : workStream === "COA Review" ? (
            <div style={{ width: "50%", margin: "auto" }}>
              <div style={{ padding: 10 }}>
                <span>Validation study</span>
                <Switch style={{ float: "right" }} />
              </div>
              <div style={{ padding: 10 }}>
                <span>Responsiveness study</span>
                <Switch style={{ float: "right" }} />
              </div>
              <div style={{ padding: 10 }}>
                <span>RCT with PRO/QoL</span>
                <Switch style={{ float: "right" }} />
              </div>
              <div style={{ padding: 10 }}>
                <span>Obs. study with PRO/QoL</span>
                <Switch style={{ float: "right" }} />
              </div>
              <div style={{ padding: 10 }}>
                <span>Lit. review of PRO/QoL</span>
                <Switch style={{ float: "right" }} />
              </div>
            </div>
          ) : null}
          <br />
          {!caregiverSelected ? (
            <div>
              {/* <span
              style={{
                background: "#fff",
                display: "inline-block",
                padding: "5px 10px",
                position: "relative",
                bottom: -15,
                left: 10,
              }}
            >
              Patients
            </span> */}
              <TagsInput
                value={patients}
                separators={["Enter"]}
                name="patients"
                placeHolder=""
                onChange={(v) => setPatients(v)}
                className="test"
              />
            </div>
          ) : null}
          {caregiverSelected ? (
            <div>
              {/* <span
                style={{
                  background: "#fff",
                  display: "inline-block",
                  padding: "5px 10px",
                  position: "relative",
                  bottom: -15,
                  left: 10,
                }}
              >
                Caregivers
              </span> */}
              <TagsInput
                value={caregivers}
                separators={["Enter"]}
                name="caregivers"
                placeHolder=""
                onChange={(v) => setCareGivers(v)}
              />
            </div>
          ) : null}
          <br />
          <Button type="primary" onClick={() => setActiveTab(3)}>
            Next
          </Button>
        </Panel>

        <Panel
          header={
            <h4>
              {workStream === "Conceptual Development"
                ? "Outcomes"
                : "Outcome Measurements"}
            </h4>
          }
          key="3"
        >
          <div>
            Extract Outcomes{" "}
            <Switch
              checked={aiConceptsChoice}
              onChange={(v) => setAiConceptsChoice(v)}
            />
          </div>
          <br />
          <TagsInput
            value={concepts}
            separators={["Enter"]}
            name="popInclusion"
            placeHolder=""
            style={{
              border: "none",
            }}
            onChange={(v) => setConcepts(v)}
          />
        </Panel>
      </Collapse>

      <Button
        type="primary"
        style={{
          float: "right",
          marginTop: 15,
        }}
        onClick={screening}
        loading={loading}
      >
        Screen
      </Button>
      <Modal
        open={dataSetModalVisible}
        width={600}
        styles={{
          body: {
            height: 200,
            paddingBottom: 30
          }
        }}
        footer={null}
        onCancel={updateDataSetModal}
        style={{ paddingBottom: 20}}
        title="Upload new dataset"
      >
        <Dragger
          {...draggerProps}
          style={{
            padding: "20px",
            margin: "20px auto 30px",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          {
            uploading ? <Spin spinning={true} /> : 
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          }
        </Dragger>
      </Modal>
    </div>
  );
}

export default Screening;
